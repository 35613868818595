<template>
    <div class="row">
       
        <div class="col-12 mb-3 mt-3">
            <b-card header="Transfer Record">
                <div class="pr-3 pt-3 pb-3 pl-3">
                    <b-row>
                        <b-col cols="3">
                            <v-date-picker
                                v-model="dateRange"
                                :model-config="modelConfig"
                                timezone="UTC"
                                is-range
                                is24hr
                                @input="getTransferRecord"
                            >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group>
                                        <b-input-group v-on:click="togglePopover">
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="CalendarIcon" />
                                            </b-input-group-prepend>
                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                        </b-input-group>
                                    </b-form-group>
                                </template>
                            </v-date-picker>
                        </b-col>
                        <b-col cols="6">
                            <v-select
                                id="transferStatus"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Status"
                                v-model="queryCondition.statusArray"
                                :options="transferStatusOptions"
                            />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <b-form-input placeholder="Record id" v-model="queryCondition.id" />
                        </b-col>
                        <b-col cols="3">
                            <b-form-input placeholder="User id" v-model="queryCondition.userId" />
                        </b-col>
                        <b-col cols="3">
                            <b-form-input placeholder="From Email" v-model="queryCondition.fromUserEmail" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col cols="3">
                            <b-form-input placeholder="From UserName" v-model="queryCondition.fromUserName" />
                        </b-col>
                        <b-col cols="3">
                            <b-form-input placeholder="From PhoneNumber" v-model="queryCondition.fromPhoneNumber" />
                        </b-col>

                        <b-col cols="1">
                            <b-button variant="secondary" @click="getTransferRecord"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="claim_record_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'amount'">
                                    {{ $tools.tokenAmountParser.parserToMoneyFormat(props.row[props.column.field], 18) }}
                                </span>

                                <span v-else-if="props.column.field === 'created_unixtime'">
                                    {{ parseTime(props.row[props.column.field]) }}
                                </span>

                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge v-if="props.row['status'] === 'pending'" pill variant="info" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'canceled'" pill variant="secondary" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'rejected'" pill variant="danger" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'finished'" pill variant="success" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'action1'">

                                <b-button v-if="props.row['status'] === 'pending'" class="mt-1" style="width:115px" variant="success" @click="approve([props.row])"> approve</b-button>
                                <b-button v-if="props.row['status'] === 'pending'" class="mt-1" style="width:115px" variant="danger"  @click="reject([props.row])"> reject</b-button>
                                <!-- <b-button v-if="props.row['status'] === 'pending'" class="mt-1" style="width:115px" variant="secondary" @click="manual_finish([props.row])"> manual_finish</b-button> -->

                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
    BTab,
    BTabs,
    BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import VueQr from "vue-qr";
import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        BTab,
        BTabs,
        BCardText,

        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
        VBTooltip,

        VueQr,
        VDatePicker,
    },

    methods: {
        log(...arg) {
            return console.log(...arg);
        },

        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },

        parseTime(timeStamp) {
            return moment.unix(timeStamp).utc().format("YYYY-MM-DD");
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getTransferRecord();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getTransferRecord();
        },

        async approve(selectedRows) {
            let approveIds = [];
            for (let i = 0; i < selectedRows.length; i++) {
                if (selectedRows[i].status!="pending"){
                    this.$bvToast.toast("claim id "+selectedRows[i].id+" status error", {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }
                approveIds.push(selectedRows[i].id)
            }

            if (approveIds.length===0){
                this.$bvToast.toast("no record selected", {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
            }

            let value = await this.$bvModal.msgBoxConfirm(`Are you sure approve transfer? `, {
                title: "approve",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.oldMsnttTransfer.approve(approveIds, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("transfer approved", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });

             

                this.getTransferRecord();
            }
        },

        async reject(selectedRows) {
            let rejectIds = [];
            for (let i = 0; i < selectedRows.length; i++) {
                if (selectedRows[i].status!="pending"){
                    this.$bvToast.toast("claim id "+selectedRows[i].id+" status error", {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }
                rejectIds.push(selectedRows[i].id)
            }

            if (rejectIds.length===0){
                this.$bvToast.toast("no record selected", {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
            }

            let value = await this.$bvModal.msgBoxConfirm(`Are you sure reject transfer? `, {
                title: "reject claim",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.oldMsnttTransfer.reject(rejectIds, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("transfer rejected", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });

                this.getTransferRecord();
            }
        },

        

        async getTransferRecord() {
            if (this.pageMounted === false) {
                return;
            }
            if (this.isQueryingTransferRecord === true) {
                return;
            }

            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            let statusArray =
                this.queryCondition.statusArray === null || this.queryCondition.statusArray.length === 0 ? null : this.queryCondition.statusArray;

            let id = parseInt(this.queryCondition.id);
            if (id === null || isNaN(id) || id <= 0) {
                id = null;
            }
            
            let userId = parseInt(this.queryCondition.userId);
            if (userId === null || isNaN(userId) || userId <= 0) {
                userId = null;
            }


            this.table_show_overlay = true;
            this.isQueryingTransferRecord = true;
            // query(id,user_id,from_user_email,from_user_name,from_phone_number,statusArray,startDate,endDate,limit,offset, token)
            let resp = await this.$api.oldMsnttTransfer.query(
                id,
                userId,
                this.queryCondition.fromUserEmail,
                this.queryCondition.fromUserName,
                this.queryCondition.fromPhoneNumber,
                statusArray,
                startDate,
                endDate,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.isQueryingTransferRecord = false;
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.records;
            this.totalRows = resp.result.count;
        },

       
    },

    mounted() {
        this.pageMounted = true;
        // this.pageDataReload();
        this.getTransferRecord();
    },
    data() {
        return {
            isQueryingTransferRecord: false,
            pageMounted: false,

            vsecs: 0,
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Date",
                    field: "created_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User Id",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "From Email",
                    field: "from_user_email",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "From UserName",
                    field: "from_user_name",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "From PhoneNum",
                    field: "from_phone_number",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Remark",
                    field: "remark",
                    tdClass: "text-center",
                    thClass: "text-center",
                    width:"150px",
                },
                {
                    label: "Amount",
                    field: "amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            
            transferStatusOptions: [
                { text: "pending", value: "pending" },
                { text: "canceled", value: "canceled" },
                { text: "rejected", value: "rejected" },
                { text: "finished", value: "finished" },
            ],

            //toChainType,statusArray,userIdArray,fromCreditNameArray,merkleRoot
            queryCondition: {
                statusArray: ["pending"],
                userId: null,
                id:null,
                fromUserEmail:null,
                fromUserName:null,
                fromPhoneNumber:null,
            },

            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 90 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },
        };
    },
};
</script>
